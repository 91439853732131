body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ventsim-debug-box {
  position: absolute; 
  top: 0; 
  right: 0;
}

.ventsim-chart{
  user-select: none;
}

.recharts-surface{
  cursor: crosshair;
}

.ventsim-iframe::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}

#embeddedsims {
  overflow: 'hidden';
  /* transform: scale(0.8); 
  transform-origin: 0 0; */
}